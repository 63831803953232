import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/restart-energy/views/loginPageStyle.jsx";
import logoClient from "assets/img/logo/novia_logo.svg";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";

const Auth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};


class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      password: '',
      redirectToReferrer: false
    };

    this.wizardManger = window.wizardManager;
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
      var that = this;
      window.loginManager.deleteUserDataInSession().then(function () {
          that.timeOutFunction = setTimeout(
              function() {
                  that.setState({ cardAnimaton: "" });
              }.bind(this),
              700
          );
      })

      var readPath = window.location.pathname.toString();

      if (readPath.includes("/auth/login/")) {
          let encode = readPath.substring(readPath.lastIndexOf("/") + 1);
          if (encode != "login" && encode != "auth") {
              window.loginManager.doAutoRegistration(encode).then(function (response) {
                  debugger;
                  that.state.username = response.username;
                  that.state.password = response.parola;
                  sessionStorage.setItem('insuranceType', response.nume);
                  that.login(null);
                  console.log(response)
              }).catch(function (response) {
                  console.log(response)
              });
          }
      }

  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  updateUsername(evt){
      this.setState({
          username: evt.target.value
      });
  }

  updatePassword(evt){
      this.setState({
          password: evt.target.value
      });
  }

  login(evt) {
    var that = this;
      window.loginManager.doLogin(this.state.username, this.state.password)
          .then(function (response) {
            Auth.authenticate(() => {
              that.setState({ redirectToReferrer: true });
            });
          }).catch(function (response) {
             alert(response.err)
          });
  }




render() {
    const { classes } = this.props;
    let { from } = this.props.location.state || { from: { pathname: "/dashbord" } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter} theme-login-header`} >
                    <img style={{width:"150px"}} src={logoClient} />
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Utilizator"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      value: this.state.username,
                      onChange : (evt) => this.updateUsername(evt)
                    }}
                  />
                  <CustomInput
                    labelText="Parola"
                    id="password"
                    inputComponent="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      value: this.state.password,
                      type:"password",
                      onChange : (evt) => this.updatePassword(evt)
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="yellow" round size="lg" block onClick={evt => this.login(evt)}>
                      Conecteaza-te
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
