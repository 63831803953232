import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { Link } from 'react-router-dom';



import dashboardStyle from "assets/jss/restart-energy/views/dashboardStyle";

const style = {
  cardDashbord:{
    marginBottom:"5px",
    marginTop:"5px"
  },
  ...dashboardStyle
};


class Dashboard extends React.Component {
  constructor(props){
      super();
      this.loginManager = window.loginManager;
  }

  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleClick = (event, value) => {
    window.loginManager.deleteUserDataInSession().then(function () {
       window.location.pathname = window.LOGIN_PATH_NAME;
    })
  };

  render() {
    let that = this;
    let urlContracts = this.loginManager.config.lightUrl + "registratura/registru/REGISTRU_CONTRACTE_RESTART/lang/RO/token/"+this.loginManager.userData.token;
    let urlOffers = this.loginManager.config.lightUrl + "registratura/registru/CAMPANII/lang/RO/token/"+this.loginManager.userData.token;
    const { classes } = this.props;
    return (

      <div class="customtitle">
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
              <Link to="/new-contract">
                <Card className={classes.cardDashbord}>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon>note_add</Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}> </p>
                    <h3 className={classes.cardTitle}>
                        CONTRACT NOU
                    </h3>
                  </CardHeader>
                </Card>
              </Link>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
              <a href={urlContracts} target={"_blank"}>
                <Card className={classes.cardDashbord}>
                      <CardHeader color="success" stats icon>
                          <CardIcon color="success">
                              <Icon>list_alt</Icon>
                          </CardIcon>
                          <p className={classes.cardCategory}> </p>
                          <h3 className={classes.cardTitle}>
                              CONTRACTELE MELE
                          </h3>
                      </CardHeader>
                  </Card>
              </a>
          </GridItem>
{/*
            <GridItem xs={12} sm={6} md={6} lg={6}>
                <a href={urlOffers} target={"_blank"}>
                  <Card className={classes.cardDashbord}>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>local_offer</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}> </p>
                            <h3 className={classes.cardTitle}>
                                OFERTE ACTIVE
                            </h3>
                        </CardHeader>
                    </Card>
                </a>
            </GridItem>
*/}
            <GridItem xs={12} sm={6} md={6} lg={6}>
                <Link to="/change_pass">
                  <Card className={classes.cardDashbord}>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <Icon>security</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}> </p>
                            <h3 className={classes.cardTitle}>
                                SCHIMBA PAROLA
                            </h3>
                        </CardHeader>
                    </Card>
                </Link>
            </GridItem>

            <GridItem xs={12} sm={6} md={6} lg={6}>
                <Link to="/eSign">
                <Card className={classes.cardDashbord}>
                    <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                            <Icon>assignment</Icon>
                        </CardIcon>
                        <p className={classes.cardCategory}> </p>
                        <h3 className={classes.cardTitle}>
                            SEMNARE ELECTRONICA
                        </h3>
                    </CardHeader>
                </Card>
                </Link>
            </GridItem>

            <GridItem xs={12} sm={6} md={6} lg={6}>
                <Card className={classes.cardDashbord} onClick={this.handleClick}>
                      <CardHeader color="rose" stats icon>
                          <CardIcon color="rose">
                              <Icon>exit_to_app</Icon>
                          </CardIcon>
                          <p className={classes.cardCategory}> </p>
                          <h3 className={classes.cardTitle}>
                              DELOGARE
                          </h3>
                      </CardHeader>
                  </Card>
            </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Dashboard);
