import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import * as utils from "helper/utils.js";

import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";

let cantitateFilterDate = [];
let judetFilterDate = [];
let localitatiFilterDate = [];
let inmatriculariFilterDate = [{name:'Inmatriculat'},{name:'In vederea inmatricularii'},{name:'Inregistrat la primarie'},{name:'In vederea inregistrarii'}];
let categoriaFilterDate = [{name:'Autoturism'},{name:'Autoutilitara'},{name:'Autovehicul pentru transport persoane'},{name:'Motocicleta'},{name:'Autotractor'},{name:'Remorca'},{name:'Tractor rutien'}];
let combustibilFilerDate = [{name:'Motorina'},{name:'Benzina'},{name:'Benzina+GPL/Hybrid'},{name:'Electric'},{name:'Alt tip de combustibil'},{name:'Fara combustibil'}]
let straziFilterDate = [];

function renderInputComponent(inputProps) {
    const {
        classes, inputRef = () => {
    }, ref, ...other
} = inputProps;

return (
    <TextField
fullWidth
InputProps={{
    inputRef: node => {
        ref(node);
        inputRef(node);
    },
        classes: {
        input: classes.input
    }
}}
{...other}
/>
);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
    <div>
    {parts.map((part, index) =>
        part.highlight ? (
        <span key={String(index)} style={{ fontWeight: 500 }}>
{part.text}
</span>
) : (
<strong key={String(index)} style={{ fontWeight: 300 }}>
{part.text}
</strong>
)
)}
</div>
</MenuItem>
);
}

function getSuggestions(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : cantitateFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}

function getSuggestionValue(suggestion) {
    //this.wizardManger.setDataValue("location", suggestion);
    //this.state.locationState = "success";
    return suggestion.name;
}

function loadCantitateLovData() {
    if (window.wizardManager.wizardData.offer != null && window.wizardManager.wizardData.offer != "undefined" && window.wizardManager.wizardData.offer.cantitateLov != null && window.wizardManager.wizardData.offer.cantitateLov != "undefined") {
        let lngth = Object.keys(window.wizardManager.wizardData.offer.cantitateLov).length;
        let vals = window.wizardManager.wizardData.offer.cantitateLov;
        if (lngth > 0) {
            cantitateFilterDate = [];
            let i = 0;
            for (i = 0; i < lngth; i++) {
                cantitateFilterDate[i] = {name: vals[i].KEY};
            }
        }
    }
}

const style = theme => ({
    cardText: {
        width: "100%",
        textAlign: "center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    container: {
        position: "relative"
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    divider: {
        height: theme.spacing.unit * 2
    },
...customSelectStyle,
...withStyles,
...customCheckboxRadioSwitch
});

function getSuggestionsJudet(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : judetFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}
function getSuggestionsLocalitati(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : localitatiFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}

function getSuggestionsInmatriculari(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : inmatriculariFilterDate.filter(suggestion => {
            const keep =
                count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}
function getSuggestionsCategoria(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : categoriaFilterDate.filter(suggestion => {
            const keep =
                count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}

function getSuggestionsStrazi(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : straziFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}

class StepLocConsumData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            furnizor_actual: "",
            furnizor_actualState: "",
            adresa_loc_consum: "",
            adresa_loc_consumState: "",
            l_tara: "Romania",
            l_judet: "",
            singleJudetId:"",
            l_localitate: "",
            singleLocalitatiId: "",
            l_strada: "",
            singleStraziId:"",
            l_nr: "",
            l_sector: "",
            l_bloc: "",
            l_scara: "",
            l_tronson: "",
            l_etaj: "",
            l_apartament: "",
            l_cod_postal: "",
            l_judetState: "",
            l_localitateState: "",
            l_stradaState: "",
            cod_nlc: "",
            cod_nlcState: "",
            cod_pod: "",
            cod_podState: "",
            cod_clc_gn: "",
            cod_clc_gnState: "",
            cantitate_lunara_estimata: "",
            cantitate_lunara_estimataState: "",
            cantitate_anuala_estimata: "",
            cantitate_anuala_estimataState: "",
            nr_contract_furnizare: "",
            suggestions: [],
            suggestionsJudet: [],
            suggestionsLocalitati: [],
            suggestionsInmatriculari: [],
            suggestionsCategoria: [],
            suggestionsCombustibil: [],
            suggestionsStrazi: [],
            unlockLocalitati: true,
            unlockStrazi: true,
            utils: utils.utils(),
            numarInmatriculare :"VN-88-LUJ",
            marca:"BMW",
            stareInmatriculare:"Inmatriculat",
            categoria:"Autoturism",
            model:"Seria 3",
            combustibil:"Benzina",
            numarInmatriculareState :"success",
            marcaState:"success",
            stareInmatriculareState:"success",
            categoriaState:"success",
            modelState:"success",
            combustibilState:"success"
        };

        this.wizardManger = window.wizardManager;
    }

    loadJudeteData() {
        let that = this;
        judetFilterDate = [];
        if (this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadJudeteData().then(function(data) {

                that.loadingJudetData = false;
                that.setState({ judetData: data.judetList });
                for(var i=0; i<data.judetList.length; i++){
                    judetFilterDate.push(data.judetList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingJudetData = false;
                    alert(err);
                });
        }

        that.loadingJudetData = false;
    }

    loadLocalitatiData() {
        let that = this;

        localitatiFilterDate = [];

        if (this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadLocalitatiData(this.state.singleJudetId).then(function(data) {

                that.loadingLocalitatiData = false;
                that.setState({ localitatiData: data.localitateList });
                for(var i=0; i<data.localitateList.length; i++){
                    localitatiFilterDate.push(data.localitateList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingLocalitatiData = false;
                    alert(err);
                });
        }

        that.loadingLocalitatiData = false;
    }

    loadStraziData() {
        let that = this;

        straziFilterDate = [];

        if (this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadStraziData(this.state.singleLocalitatiId).then(function(data) {

                that.loadingStraziData = false;
                that.setState({ straziData: data.straziList });
                for(var i=0; i<data.straziList.length; i++){
                    straziFilterDate.push(data.straziList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingStraziData = false;
                    alert(err);
                });
        }

        that.loadingStraziData = false;
    }

    sendState() {
        return this.state;
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    phoneNumber(value)
    {
        var phoneno = /^\d{10}$/;
        if(value.match(phoneno))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    realNumber(value){
        return (!isNaN(parseFloat(value)) && isFinite(value));
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        this.setState({ [stateName]: event.target.value });
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "cod_pod":
                if (window.wizardManager.wizardData.type == 'electricity') {
                    if (event.target.value) {
                        this.setState({[stateName + "State"]: "success"});
                    } else {
                        this.setState({[stateName + "State"]: "error"});
                    }
                }
                break;
            case "cod_clc_gn":
                if (window.wizardManager.wizardData.type == 'gas') {
                    if (event.target.value) {
                        this.setState({[stateName + "State"]: "success"});
                    } else {
                        this.setState({[stateName + "State"]: "error"});
                    }
                }
                break;
            case "cantitate":
                if (this.realNumber(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "mandatory":
                if (this.mandatory(event.target.value)) {
                    this.setState({ [stateName  + "State"]: "success" });
                } else {
                    this.setState({ [stateName  + "State"]: "error" });
                }
                break;
            default:
                break;
        }
    }
    reloadState(){
        this.wizardManger.setDataValue('locConsum', null);
        this.setState({ locConsum: "", locConsumData: null });
    }
    mandatory(value) {
        return value !== "" && value !== null ? true : false;
    }

    setAdress(){
        this.state.adresa_loc_consum = "";
        this.checkNullAndAdd("Tara", this.state.l_tara);
        this.checkNullAndAdd("Judet", this.state.l_judet);
        this.checkNullAndAdd("Localitate", this.state.l_localitate);
        this.checkNullAndAdd("Str.", this.state.l_strada);
        this.checkNullAndAdd("Nr.",this.state.l_nr);
        this.checkNullAndAdd("Sec.",this.state.l_sector);
        this.checkNullAndAdd("Bl.",this.state.l_bloc);
        this.checkNullAndAdd("Sc.",this.state.l_scara);
        this.checkNullAndAdd("Trons.",this.state.l_tronson);
        this.checkNullAndAdd("Et.",this.state.l_etaj);
        this.checkNullAndAdd("Ap.",this.state.l_apartament);
        this.checkNullAndAdd("Cod pos.",this.state.l_cod_postal);
    }

    checkNullAndAdd(name, val){
        if (val !== "" && val !== null){
            if (!this.state.adresa_loc_consum.includes(",") && Object.keys(this.state.adresa_loc_consum).length == 0){
                this.state.adresa_loc_consum = name + " " + val;
            } else {
                this.state.adresa_loc_consum = this.state.adresa_loc_consum + ", " + name + " " + val;
            }
        }
    }


    continue() {
        if (window.wizardManager.wizardData.type == 'electricity'){
            if (this.state.cod_podState === "success") {
                this.setAdress();
                this.wizardManger.setDataValue('locConsum', this.state);
                this.state.utils.triggerStepNext();
            }
        }
        if (window.wizardManager.wizardData.type == 'gas'){
            if (this.state.cod_clc_gnState === "success") {
                this.setAdress();
                this.wizardManger.setDataValue('locConsum', this.state);
                this.state.utils.triggerStepNext();
            }
        }

    }

    allMandatoryValid(){
        if (this.state.numarInmatriculareState === "success" &&
            this.state.marcaState === "success" &&
            this.state.modelState === "success" &&
            this.state.combustibilState === "success") {
            return true;
        }
        return false;
    }

    getValueByKey(){
        let lngth = Object.keys(this.wizardManger.wizardData.offer.cantitateLov).length;
        let vals = this.wizardManger.wizardData.offer.cantitateLov;
        let i = 0;
        for (i=0; i<lngth; i++ ){
            if (vals[i].KEY == this.state.cantitate_lunara_estimata){
                return vals[i].VALUE;
            }
        }
    }

    isValidated() {
        debugger;
        let that = this;
        that.errMsg = '';
        if (window.wizardManager.wizardData.type == 'vehicle') {
            debugger;
            if (this.allMandatoryValid()) {
                this.wizardManger.setDataValue('locConsum', this.state);
                debugger;
                return true;
            } else {
               /* if (this.state.stareInmatriculareState !== "success") {
                    that.errMsg = 'Starea de inmatriculare nu este valida !';
                    this.setState({stareInmatriculareState: "error"});
                }*/
                if (this.state.numarInmatriculareState !== "success" ) {
                    that.errMsg = 'Completati numarul de inmatriculare!';
                    this.setState({numarInmatriculareState: "error"});
                }
                if (this.state.marcaState !== "success") {
                    that.errMsg = 'Completati marca!!';
                    this.setState({marcaState: "error"});
                }
               /* if (this.state.categoriaState !== "success") {
                    that.errMsg = 'Completati categoria autoturismului!';
                    this.setState({categoriaState: "error"});
                }*/
                if (this.state.modelState !== "success") {
                    that.errMsg = 'Completati modelul autoturismului!';
                    this.setState({modelState: "error"});
                }
                if (this.state.combustibilState !== "success") {
                    that.errMsg = 'Completati tipul de combustibil!';
                    this.setState({combustibilState: "error"});
                }
            }
            return false;
        }
    }


    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    }

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    }

    handleSuggestionsFetchRequestedJudet = ({ value }) => {
        this.setState({
            suggestionsJudet: getSuggestionsJudet(value),
        });
    }

    handleSuggestionsClearRequestedJudet = () => {
        this.setState({
            suggestionsJudet: []
        });
    }

    handleSuggestionsFetchRequestedLocalitati = ({ value }) => {
        this.setState({
            suggestionsLocalitati: getSuggestionsLocalitati(value),
        });
    }

    handleSuggestionsClearRequestedLocalitati = () => {
        this.setState({
            suggestionsLocalitati: []
        });
    };

    handleSuggestionsFetchRequestedInmatriculari = ({ value }) => {
        this.setState({
            suggestionsInmatriculari: getSuggestionsInmatriculari(value),
        });
    };

    handleSuggestionsClearRequestedInmatriculari = () => {
        // this.setState({
        //     suggestionsInmatriculari: []
        // });
    };
    handleSuggestionsFetchRequestedCategoria = ({ value }) => {
        this.setState({
            suggestionsCategoria: getSuggestionsCategoria(value),
        });
    };

    handleSuggestionsClearRequestedCategoria = () => {
       /* this.setState({
            suggestionsCategoria: getSuggestionsCategoria(value),
        });*/
    };




    handleSuggestionsFetchRequestedStrazi = ({ value }) => {
        this.setState({
            suggestionsStrazi: getSuggestionsStrazi(value),
        });
    };

    handleSuggestionsClearRequestedStrazi = () => {
        this.setState({
            suggestionsStrazi: []
        });
    }

    handleChange = name => (event, { newValue }) => {
        debugger;
        this.setState({
            [name]: newValue
        });

        if (cantitateFilterDate != "undefined" && Object.keys(cantitateFilterDate).length > 0 && (name == "cantitate_lunara_estimata" || name == "cantitate_anuala_estimata")) {
            let bol = false;
            let lngth = Object.keys(cantitateFilterDate).length;
            let i = 0;
            for (i = 0; i < lngth; i++) {
                if (cantitateFilterDate[i].name == newValue) {
                        bol = true;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
        }
        if (judetFilterDate != "undefined" && Object.keys(judetFilterDate).length > 0 && name == "l_judet") {
            this.setState({
                [name]: newValue
            });
            var bol = false;
            var lngth = Object.keys(judetFilterDate).length;
            var i = 0;
            this.state.unlockLocalitati = true;
            this.state.unlockStrazi = true;
            this.state.l_localitate = "";
            this.state.l_strada = "";
            this.state.singleLocalitatiId = "";
            for (i = 0; i < lngth; i++) {
                if (judetFilterDate[i].name == newValue) {
                    bol = true;
                    this.state.unlockLocalitati = false;
                    this.state.singleJudetId = judetFilterDate[i].countyId;
                    this.loadLocalitatiData();
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }
        if (localitatiFilterDate != "undefined" && localitatiFilterDate.length > 0 && name == "l_localitate") {
            this.setState({
                [name]: newValue
            });
            let bol = false;
            let lngth = localitatiFilterDate.length;
            let i = 0;
            this.state.unlockStrazi = true;
            this.state.l_strada = "";
            this.state.singleLocalitatiId = "";
            for (i = 0; i < lngth; i++) {
                if (localitatiFilterDate[i].name == newValue) {
                    this.state.unlockStrazi = false;
                    this.state.singleLocalitatiId = localitatiFilterDate[i].localityId;
                    this.loadStraziData();
                    bol = true;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }

        if (straziFilterDate != "undefined" && straziFilterDate.length > 0 && name == "l_strada") {
            this.setState({
                [name]: newValue
            });
            let bol = false;
            let lngth = straziFilterDate.length;
            let i = 0;
            for (i = 0; i < lngth; i++) {
                if (straziFilterDate[i].name == newValue) {
                    bol = true;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }
    };


    render() {

        loadCantitateLovData();

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        const autosuggestPropsJudet = {
            renderInputComponent,
            suggestions: this.state.suggestionsJudet,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedJudet,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedJudet,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        const autosuggestPropsLocalitati = {
            renderInputComponent,
            suggestions: this.state.suggestionsLocalitati,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedLocalitati,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedLocalitati,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };
        const autosuggestPropsInmatriculare = {
            renderInputComponent,
            suggestions: this.state.suggestionsInmatriculari,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedInmatriculari,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedInmatriculari,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };
        const autosuggestPropsCategoria = {
            renderInputComponent,
            suggestions: this.state.suggestionsCategoria,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedCategoria,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedCategoria(),
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        const autosuggestPropsStrazi = {
            renderInputComponent,
            suggestions: this.state.suggestionsStrazi,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedStrazi,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedStrazi,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        if (!this.state.judetData) {
            if (!this.loadingJudetData) {
                this.loadingJudetData = true;
                this.loadJudeteData();
            }

            return <div>Loading...</div>;
        }

        let that = this;

        const { classes } = this.props;
        return (
            <GridContainer justify="center">
        <Card>
        <CardHeader color="warning" text>
        <CardText className={classes.cardText} color="warning">
    <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Date despre autovehicul</h4>
<h4 className={classes.cardCategoryWhite}>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
    <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
</h4>
</CardText>
</CardHeader>
<CardBody>
<GridItem xs={12} sm={12} md={12} lg={12}>
    {<Autosuggest
        {...autosuggestPropsInmatriculare}
        inputProps={{
            classes,
            label: 'Stare Inmatriculare',
            placeholder: 'Introdu primele 3 litere',
            value: this.state.stareInmatriculare,
            onChange: this.handleChange('stareInmatriculare')
        }}
        theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
                {options.children}
            </Paper>
        )}
    />}

    <CustomInput
success={this.state.numarInmatriculare === "success"}
error={this.state.numarInmatriculare === "error"}
labelText={
<span>
Numar inmatriculare <small>(obligatoriu)</small>
</span>
}
id="numarInmatriculare"
formControlProps={{
    fullWidth: true
}}
inputProps={{
    value:this.state.numarInmatriculare,
    onChange: event => that.change(event, "numarInmatriculare", "mandatory")
}}
/>

    {<Autosuggest
        {...autosuggestPropsCategoria}
        inputProps={{
            classes,
            label: 'Categoria (obligatoriu)',
            placeholder: 'Introdu primele 3 litere',
            value: this.state.categoria,
            onChange: this.handleChange('categoria')
        }}
        theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
                {options.children}
            </Paper>
        )}
    />}

    <CustomInput
        success={this.state.marcaState === "success"}
        error={this.state.marcaState === "error"}
        labelText={
            <span>
Marca  <small>(obligatoriu)</small>
</span>
        }
        id="marca"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            value:this.state.marca,
            onChange: event => that.change(event, "marca", "mandatory")
        }}

    />

    <CustomInput
    success={this.state.modelState === "success"}
    error={this.state.modelState === "error"}
    labelText={
        <span>
Model  <small>(obligatoriu)</small>
</span>
    }
    id="model"
    formControlProps={{
        fullWidth: true
    }}
    inputProps={{
        value:this.state.model,
        onChange: event => that.change(event, "model", "mandatory")
    }}
/>
    <CustomInput
        success={this.state.combustibil === "success"}
        error={this.state.combustibil === "error"}
        labelText={
            <span>
Combustibil  <small>(obligatoriu)</small>
</span>
        }
        id="combustibil"
        formControlProps={{
            fullWidth: true
        }}
        inputProps={{
            value:this.state.combustibil,
            onChange: event => that.change(event, "combustibil", "mandatory")
        }}
    />

{getEEGNFields(window.wizardManager.wizardData.type, that)}


{getGasErnergFields(window.wizardManager.wizardData.type, window.wizardManager.wizardData.subscriptionType, that, classes, autosuggestProps)}

</GridItem>
</CardBody>
</Card>
</GridContainer>
);
}
}

export default withStyles(style)(StepLocConsumData);

function getEEGNFields(state, val){
    switch(state){
        case 'gas':
            return <CustomInput
            success={val.state.cod_clc_gnState === "success"}
    error={val.state.cod_clc_gnState === "error"}
labelText={
<span>phone
Cod CLC GN <small>(obligatoriu)</small>
</span>
}
id="cod_clc_gn"
formControlProps={{
    fullWidth: true
}}
inputProps={{
    onChange: event => val.change(event, "cod_clc_gn", "cod_clc_gn")
}}
/>
case 'electricity':
return <div> <CustomInput
labelText={
<span>
Cod NLC
</span>
}
id="cod_nlc"
formControlProps={{
    fullWidth: true
}}
inputProps={{
    onChange: event => val.change(event, "cod_nlc", "cod_nlc")
}}
/>
<CustomInput
success={val.state.cod_podState === "success"}
error={val.state.cod_podState === "error"}
labelText={
<span>
Cod POD <small>(obligatoriu)</small>
</span>
}
id="cod_pod"
formControlProps={{
    fullWidth: true
}}
inputProps={{
    onChange: event => val.change(event, "cod_pod", "cod_pod")
}}
/> </div>
}
}


function getGasErnergFields(state, oferType, val, classes, autosuggestProps1){
    switch(state){
        case 'electricity':
            return getOfferTypeFieldsElec(oferType, val, classes, autosuggestProps1);
    case 'gas':
    return getOfferTypeFieldsGas(oferType, val, classes,autosuggestProps1);
    }
}

function getOfferTypeFieldsElec(oferType, val, classes, autosuggestProps1){
    switch(oferType){
        case 'go':
            return <div> <CustomInput
                        success={val.state.cantitate_lunara_estimataState === "success"}
                        error={val.state.cantitate_lunara_estimataState === "error"}
                        labelText={
                        <span>
                        Cantitate lunara estimata MWh <small>(obligatoriu)</small>
                        </span>
                        }
                        id="cantitate_lunara_estimata"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => val.change(event, "cantitate_lunara_estimata", "cantitate")
                        }}
                        /> </div>;
    case 'fix':
    return <div><Autosuggest
            {...autosuggestProps1}
success={val.state.cantitate_lunara_estimataState === "success"}
error={val.state.cantitate_lunara_estimataState === "error"}
            inputProps={{
                    classes,
                    label: 'Cantitate lunara estimata MWh (obligatoriu)',
                    placeholder: 'Introdu primele 3 cifre',
                    value: val.state.cantitate_lunara_estimata,
                    onChange: val.handleChange('cantitate_lunara_estimata')
            }}
            theme={{
                container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
            {options.children}
            </Paper>
            )}
            /></div>
    }
}


function getOfferTypeFieldsGas(oferType, val, classes, autosuggestProps1){
    switch(oferType){
        case 'go':
            return <div> <CustomInput
            labelText={
            <span>
            Nr. Contract furnizare actual
        </span>
    }
    id="nr_contract_furnizare"
    formControlProps={{
        fullWidth: true
    }}
    inputProps={{
        onChange: event => val.change(event, "nr_contract_furnizare", "length", 400)
    }}
    />
    <CustomInput
    success={val.state.cantitate_anuala_estimataState === "success"}
    error={val.state.cantitate_anuala_estimataState === "error"}
    labelText={
    <span>
    Cantitate anuala estimata MWh <small>(obligatoriu)</small>
    </span>
    }
    id="cantitate_anuala_estimata"
    formControlProps={{
        fullWidth: true
    }}
    inputProps={{
        onChange: event => val.change(event, "cantitate_anuala_estimata", "cantitate")
    }}
    /></div>
    case 'fix':
    return <div> <CustomInput
    labelText={
    <span>
    Nr. Contract furnizare actual
    </span>
    }
    id="nr_contract_furnizare"
    formControlProps={{
        fullWidth: true
    }}
    inputProps={{
        onChange: event => val.change(event, "nr_contract_furnizare", "length", 400)
    }}
    />
<Autosuggest
{...autosuggestProps1}
success={val.state.cantitate_lunara_estimata === "success"}
error={val.state.cantitate_lunara_estimata === "error"}
inputProps={{
    classes,
        label: 'Cantitate lunara estimata MWh (obligatoriu)',
        placeholder: 'Introdu primele 3 cifre',
        value: val.state.cantitate_lunara_estimata,
        onChange: val.handleChange('cantitate_lunara_estimata')
}}
theme={{
    container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
}}
renderSuggestionsContainer={options => (
<Paper {...options.containerProps} square>
{options.children}
</Paper>
)}
/>
</div>
    }
}