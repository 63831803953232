import axios from "axios/dist/axios";
import * as moment from 'moment';

export class WizardManager {
    constructor(props){
        this.STD_EXPIRE_CHECK_MINS = 10;
        this.loginManager = window.loginManager;
        this.wizardData = {};
    }

    resetWizardData(){
        this.wizardData = {};
    }

    setDataValue(key, value){
        this.wizardData[key] = value;
        this.storeWizardDataInSession();
    }

    storeWizardDataInSession(){
        sessionStorage.setItem('wizardData', JSON.stringify(this.wizardData));
    }

    loadWizardDataFromSession(){
        let wizardData = sessionStorage.getItem('wizardData');
        if (typeof wizardData === 'undefined' || wizardData ===  null || wizardData === ''){
            wizardData = "{}";
        }

        wizardData = JSON.parse(wizardData);
        this.wizardData = wizardData;
    }

    loadCrmCall(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/sendToCrm",
                    {
                        tip: that.wizardData.type,
                        info: that.wizardData
                    },
                    {
                        headers: {
                                    ...that.loginManager.config.corsHeaders
                                }
                    }).then(function (response) {
                            if (typeof response !== 'undefined' && response
                                && typeof response.data !== 'undefined' && response.data
                                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                                resolve(response.data);
                            } else {
                                var err = response.data.info;
                                if (typeof err === 'undfined' || !err){
                                    err = 'Unable to load data.'
                                }

                                reject(err);
                            }
                        }).catch(function (response) {
                            reject(response);
                        });
                }).catch(function (err) {
                reject(err);
            });
            });

            return promise;
            }

    loadLocationData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReZoneOperare",
                    {
                        tip: that.wizardData.type
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

loadJudeteData(){
    let that = this;

    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReJudete",
                {
                    tip: that.wizardData.type
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}

loadLocalitatiData(countyId){
    let that = this;
    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReLocalitate",
                {
                    tip: that.wizardData.type,
                    countyId: countyId
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}

loadStraziData(localityId){
    let that = this;
    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReStrazi",
                {
                    tip: that.wizardData.type,
                    localityId: localityId
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}
    loadOfferData(){
        let that = this;
        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getAsigOffers",
                    {
                        tip: that.wizardData.type,
                        location: that.wizardData.location
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                        resolve(response.data);
                    } else {
                        var err = response.data.info;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to load data.'
                        }

                        reject(err);
                    }
                }).catch(function (response) {
                    reject(response);
                });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    loadDocumentData(){
        let that = this;
        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getDocObligatoriiAsig",
                    {
                        idFisier: that.wizardData.offer.idFisier
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    loadSignDocumentData(){
        let that = this;
        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getFisiereSemnaturaAsig",
                    {
                        idFisier: that.wizardData.offer.idFisier
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    getRegInfoObj(){
        let regInfoObj = {};
        regInfoObj.customer = window.wizardManager.wizardData.customer;
        regInfoObj.detaliiContractuale = window.wizardManager.wizardData.detaliiContractuale;
        regInfoObj.formularGdpr = window.wizardManager.wizardData.formularGdpr;
        regInfoObj.locConsum = window.wizardManager.wizardData.locConsum;
        regInfoObj.location = window.wizardManager.wizardData.location;
        regInfoObj.offer = window.wizardManager.wizardData.offer;
        regInfoObj.signDocuments = window.wizardManager.wizardData.signDocuments;
        regInfoObj.subscriptionType = window.wizardManager.wizardData.subscriptionType;
        regInfoObj.type = window.wizardManager.wizardData.type;
        regInfoObj.geoLocation = {};
        if (typeof window.wizardManager.wizardData.geoLocation !== 'undefined' && window.wizardManager.wizardData.geoLocation !== null) {
            regInfoObj.geoLocation.latitude = window.wizardManager.wizardData.geoLocation.latitude;
            regInfoObj.geoLocation.longitude = window.wizardManager.wizardData.geoLocation.longitude;
            regInfoObj.geoLocation.addr = window.wizardManager.wizardData.geoLocationAddr;
        }
        return regInfoObj;
    }

    getRegInfoB64(){
        let regInfoObj = this.getRegInfoObj();
        let regInfoJson = JSON.stringify(regInfoObj);

        return window.utf8_to_b64(regInfoJson);
    }


    getLocationInfo(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(function(position){
                resolve(position.coords);
            }, function(err){
                reject(err);
            });
        });

        return promise;
    }

    getReverseGeocoding(lat, lng){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            axios.get("https://eu1.locationiq.com/v1/reverse.php?key="+that.loginManager.config.geocodeKey+"&lat="+lat+"&lon="+lng+"&format=json",
                null,
                null
                ).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.display_name !== 'undefined' && response.data.display_name){

                        resolve(response.data.display_name);
                    } else {
                        reject('Invalid response.');
                    }
                }).catch(function (response) {
                    reject(response);
                });
        });

        return promise;
    }

    getPreviewSignDownloadLink(id){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getFisierSemnaturaAsigLink/" + id,
                    {
                        regInfoB64: that.getRegInfoB64()
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
                }).catch(function (err) {
                    reject(err);
                });
        });

        return promise;
    }

    uploadPDF(){
        let that = this;
        let promise = new Promise((resolve, reject) => {
                that.loginManager.checkToken().then(function () {
                const data = new FormData();
                let file = '';
                console.warn(file);
                if (typeof that.wizardData.pdfFile !== 'undefined' && that.wizardData.pdfFile){
                    console.warn(that.wizardData.pdfFile);
                    file = JSON.stringify(that.wizardData.pdfFile);
                    console.warn(file);
                }
                data.append("file", file);

                axios.post(that.loginManager.config.wsUrl + 'api/v1/re/' + that.loginManager.userData.token + "/uploadFile/",
                    data,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Credentials': 'true',
                            'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE, HEAD',
                            'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Origin, Accept, X-Requested-With, Content-Type, X-PINGOTHER, Authorization'
                        }
            }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data){

                        resolve(response.data);
                    } else {
                        var err = response.data.errString;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to load response.'
                        }

                        reject(err);
                    }
                }).catch(function (response) {
                    reject(response);
                });
            }).catch(function (err) {
                reject(err);
            });

    });

        return promise;
    }

    eSigned(){
        debugger;
        let that = this;
        let promise = new Promise((resolve, reject) => {
                that.loginManager.checkToken().then(function () {
                if (that.wizardData.cnp != "" && that.wizardData.pin != "" &&
                    that.wizardData.pin != "" && that.wizardData.pin != "") {
                    var data = new FormData();
                    let cnp = that.wizardData.cnp;
                    let pin = that.wizardData.pin;
                    let utilizator = that.wizardData.utilizator;
                    let parola = that.wizardData.parola;
                    let docId = that.wizardData.docId;

                    data.append("cnp",cnp);
                    data.append("pin",pin);
                    data.append("utilizator",utilizator);
                    data.append("parola",parola);
                    data.append("docId",docId);

                    axios.post(that.loginManager.config.wsUrl + 'api/v1/'+that.loginManager.userData.token +'/remote_sign_pdf/',
                        data,
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Credentials': 'true',
                                'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE, HEAD',
                                'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Origin, Accept, X-Requested-With, Content-Type, X-PINGOTHER, Authorization'
                            }
                        }).then(function (response) {
                        //handle success
                        resolve(response.data);
                        console.log(response);
                    }).catch(function (response) {
                        //handle error
                        reject(response);
                    });
                }
            })
        })

        return promise;
    }



    advancedSigned(){
        let that = this;
        let promise = new Promise((resolve, reject) => {
                that.loginManager.checkToken().then(function () {
                var data = new FormData();
                data.append("token",that.loginManager.userData.token);
                if (typeof that.wizardData.docId !== 'undefined' && that.wizardData.docId !== ""  && that.wizardData.docSigniture && that.wizardData.selectedStep != "imageUpload"){
                    data.append("idFile", that.wizardData.docId);
                    var blob= new Blob();
                    data.append("fileData",blob);
                    var today = new Date();
                    var newDate =  moment(today, 'DD-MM-YYYY HH:mm:ss');
                    var date = newDate.format("DD-MM-YYYY HH:mm:ss");
                    var text = "Semnat la data "+date + " , de catre "+ that.wizardData.lastName + " " + that.wizardData.firstName;
                    data.append("textDirect",text);
                    data.append("genQrCode", 0);
                    data.append("qrCodeText", text);

                }
                if (typeof that.wizardData.docId !== 'undefined' && that.wizardData.docId !== ""  && that.wizardData.docSigniture &&
                    that.wizardData.selectedStep == "imageUpload" && typeof that.wizardData.uploadImage !== 'undefined'  ){
                    data.append("idFile", that.wizardData.docId);
                    var blob= new Blob();
                    data.append("fileData",blob);
                    var today = new Date();
                    var newDate =  moment(today, 'DD-MM-YYYY HH:mm:ss');
                    var date = newDate.format("DD-MM-YYYY HH:mm:ss");
                    var text = "Conform cu originalul. Data :  "+date + "\n Semnatura "+ that.wizardData.lastName + " " + that.wizardData.firstName;

                    data.append("image",that.wizardData.uploadImage);
                    data.append("textDirect",text);
                    data.append("genQrCode", 0);
                    data.append("qrCodeText", text);

                }

                if (typeof that.wizardData.imageFile !== 'undefined' && that.wizardData.docSigniture){
                    data.append("fileData",that.wizardData.imageFile);
                }

                data.append("signatureImage", that.wizardData.docSigniture, 'signature.png');
                data.append("codHtmlTemplate", "TMPL_SIGN_FILE");
                data.append("showTimestamp ", 0);
                data.append("lat ", 4.5);
                data.append("lng ", 8.3);

                axios({
                    method: 'post',
                    url: that.loginManager.config.wsUrl + 'api/v1/re/advancedSign',
                    data: data,
                    config: { headers: {...that.loginManager.config.corsHeaders}}
            }).then(function (response) {
                    //handle success
                    resolve(response.data);
                    console.log(response);
                }).catch(function (response) {
                    //handle error
                    reject.log(response);
                });

            });
    });

        return promise;
    }

    generareSemnaturaElectronica(){
        let that = this;
        debugger;
        let promise = new Promise((resolve, reject) => {
                that.loginManager.checkToken().then(function () {
                if (typeof that.wizardData.numePersoana !== 'undefined'){
                    axios.get(that.loginManager.config.wsUrl + '/api/v1/remote_signature/' + that.loginManager.userData.token + "/test/"+that.wizardData.numePersoana,
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Credentials': 'true',
                                'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE, HEAD',
                                'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Origin, Accept, X-Requested-With, Content-Type, X-PINGOTHER, Authorization'
                            }
                         }).then(function (response) {

                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data){
                            resolve('OK');
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });}
            }).catch(function (err) {
                reject(err);
            });

    });

        return promise;
    }

    createReDocument(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                const data = new FormData();

                if (typeof that.wizardData.documents !== 'undefined' && that.wizardData.documents){
                    Object.keys(that.wizardData.documents).forEach(function(id) {
                        data.append("file_"+id, that.wizardData.documents[id]);
                    });
                }

                data.append("regInfoB64", that.getRegInfoB64());

                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/createDocumentAsig",
                    data,
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    scanOcr(file){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                const data = new FormData();

                if (typeof file !== 'undefined' && file !== null){
                    data.append("fileData", file);
                }

                axios.post(that.loginManager.config.wsUrl + '/api/v1/file/' + that.loginManager.userData.token + "/uploadForCIOcrScan",
                    data,
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
                }).catch(function (err) {
                    reject(err);
                });
            });

        return promise;
    }
}