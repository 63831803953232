import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ImageUpload from "components/CustomUpload/ImageUpload.jsx";



import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

import * as utils from "helper/utils.js";
import Button from "@material-ui/core/Button";


import {
    cardTitle,
    roseColor
} from "assets/jss/restart-energy.jsx";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const styles = {
    cardText:{
        width:"100%",
        textAlign:"center"
    },
    infoText:{
        width:"100%",
        textAlign:"center",
        color:"#ff6666"
    },
    cardTitle,
    cardTitleWhite: {
        ...cardTitle,
        color: "#FFFFFF",
        marginTop: "0"
    },
    cardCategoryWhite: {
        margin: "0",
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: ".875rem"
    },
    cardCategory: {
        color: "#999999",
        marginTop: "10px"
    },
    icon: {
        color: "#333333",
        margin: "10px auto 0",
        width: "130px",
        height: "130px",
        border: "1px solid #E5E5E5",
        borderRadius: "50%",
        lineHeight: "174px",
        "& svg": {
            width: "55px",
            height: "55px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            width: "55px",
            fontSize: "55px"
        }
    },
    iconRose: {
        color: roseColor
    },
    marginTop30: {
        marginTop: "30px"
    },
    testimonialIcon: {
        marginTop: "30px",
        "& svg": {
            width: "40px",
            height: "40px"
        }
    },
    cardTestimonialDescription: {
        fontStyle: "italic",
        color: "#999999"
    },
    mandatoryLegend: {
        color: "#FF0000"
    }
};

class StepCustomerDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utils: utils.utils()
        };

        this.wizardManger = window.wizardManager;
    }

    fileUploaded(){

    }

    sendState() {
        return this.state;
    }

    reloadState(){

    }

    getPreloadedImage(docId){
        let that = this;
        if (typeof this.state.documentData !== 'undefined' && this.state.documentData) {
            for (let doc of this.state.documentData) {
                if (doc.cod === 'COPIE_BI_CI_PASAPORT'){
                    if (typeof that.wizardManger.wizardData.customer !== 'undefined'
                        && that.wizardManger.wizardData.customer
                        && typeof that.wizardManger.wizardData.customer.ci !== 'undefined'
                        && that.wizardManger.wizardData.customer.ci !== null
                        && doc.id_document === docId){

                        return that.wizardManger.wizardData.customer.ci;
                    }
                }
            }
        }

        return null;
    }

    setOrChangeImage(docId, imageData){
        var documents = this.state.documents;
        if (typeof documents === 'undefined' || !documents){
            documents = {};
        }

        documents[docId] = imageData;
        this.setState({ documents: documents});
        this.wizardManger.setDataValue('documents', documents);
    }

    removeImage(docId){
        var documents = this.state.documents;
        if (typeof documents === 'undefined' || !documents){
            documents = {};
        }

        delete documents[docId];
        this.setState({ documents: documents});
        this.wizardManger.setDataValue('documents', documents);
    }

    loadDocumentData(){
        let that = this;
        if (this.wizardManger.wizardData.step.stepId === 'customer_documents' &&
            typeof this.wizardManger.wizardData.offer !== 'undefined' && this.wizardManger.wizardData.offer &&
            typeof this.wizardManger.wizardData.offer.idFisier !== 'undefined' && this.wizardManger.wizardData.offer.idFisier){

            this.wizardManger.loadDocumentData().then(function(data){
                that.loadingDocumentData = false;
                that.setState({ documentData: data.docObligatoriuList });
            })
            .catch(function(err){
                that.loadingDocumentData = false;
                alert(err);
            });
        }

        that.loadingDocumentData = false;
    }

    isValidated() {
        let that = this;
        that.errMsg = '';

        if (this.state.documentData) {
            for (let doc of this.state.documentData) {
                if (doc.obligatoriu) {
                    let documents = this.state.documents;
                    if (typeof documents === 'undefined' || !documents){
                        documents = {};
                    }

                    if (typeof documents[doc.id_document] === "undefined" || documents[doc.id_document] === null){
                        return false;
                    }

                    let name = documents[doc.id_document].name.toLowerCase();
                    if (!(name.endsWith('.png')
                        || name.endsWith('.jpg')
                        || name.endsWith('.jpeg')
                        || name.endsWith('.gif')
                        || name.endsWith('.bmp'))){
                        that.errMsg = 'Incarcati doar imagini!';
                        return false;
                    }
                }
            }
        }
        return true;
    }

    render() {
        let that = this;
        const { classes } = this.props;
        debugger;
        if (!this.state.documentData){
            if (!this.loadingDocumentData){
                this.loadingDocumentData = true;
                this.loadDocumentData();
            }

            return <div>Loading...5</div>
        }

        let itemlist = this.state.documentData.map(function (item) {
            return(
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div class="card_box" style={{textAlign:"center"}}>
                            <legend className={item.obligatoriu ? classes.mandatoryLegend : null}>{item.denumire}</legend>
                            <ImageUpload
                                addButtonProps={{
                                    color: "regular",
                                    round: true
                                }}
                                changeButtonProps={{
                                    color: "regular",
                                    round: true
                                }}
                                removeButtonProps={{
                                    color: "regular",
                                    round: true
                                }}
                                advProps={{
                                    id: item.id_document,
                                    parent: that
                                }}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            );
        });

        if (typeof itemlist === 'undefined' || !itemlist || itemlist.length === 0){
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="warning" text>
                                    <CardText className={classes.cardText} color="success">
                                        <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Scanare documente asigurat</h4>
                                        <h4 className={classes.cardCategoryWhite}>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        </h4>
                                    </CardText>
                                </CardHeader>
                                <CardBody>
                                    Nu s-au gasit documente
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" text>
                                <CardText className={classes.cardText} color="success">
                                    <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Scanare documente asigurat</h4>
                                    <h4 className={classes.cardCategoryWhite}>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                        <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                    </h4>
                                </CardText>
                            </CardHeader>
                            <CardBody>
                                <h4 style={{width:"100%"}} className={classes.infoText}>Dupa ce incarcati documentele solicitate va rugam sa semnati fiecare document, folosind butonul "<i class="fas fa-file-signature"></i>"</h4>
                                {itemlist}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(styles)(StepCustomerDocuments);
