import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// core components
import Button from "components/CustomButtons/Button.jsx";
// core components
import "react-datepicker/dist/react-datepicker.css";
// material-ui components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { SketchField, Tools } from "react-sketch";
import * as utils from "helper/utils.js";
import {withStyles,style} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import defaultImage from "assets/img/image_placeholder2.png";
import defaultAvatar from "assets/img/placeholder.jpg";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}



class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      fileName:"",
        utils: utils.utils(),
        canvas: [],
        docID: "",
        docSigniture:"",
        imageFile:"",
        downloadImageLink:"",
        showImagePreview:false,
        progressIndicatorOn: false
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.parent = props.advProps.parent;
    this.id = props.advProps.id
      this.wizardManger = window.wizardManager;
  }

  unlock(){
      var past = true;
      var images = document.getElementsByTagName("img");

      for(var i=0;i<images.length;i++){
          if(images[i].getAttribute("alt") != ""){
              this.state.utils.showStepNext();
          }
      }

  }

  tryLoadPreloadedImage(){
      if (typeof this.state.file === 'undefined' || this.state.file === null) {
          let that = this;
          if (typeof that.parent !== 'undefined' && that.parent
              && typeof that.id !== 'undefined' && that.id
              && typeof that.parent.getPreloadedImage === 'function') {
              let file = that.parent.getPreloadedImage(that.id);

              if (typeof file !== 'undefined' && file !== null) {
                  let reader = new FileReader();
                  reader.onloadend = () => {
                      this.setState({
                          file: file,
                          imagePreviewUrl: reader.result,
                          fileName:file.name
                      });

                      if (typeof that.parent !== 'undefined' && that.parent
                          && typeof that.id !== 'undefined' && that.id
                          && typeof that.parent.setOrChangeImage === 'function'){
                          that.parent.setOrChangeImage(that.id, file);
                      }
                  };
                  reader.readAsDataURL(file);
              }
          }
      }
  }

  handleImageChange(e) {
      debugger;
    let that = this;
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      let name = file.name;
        if(name.endsWith('.png')
        || name.endsWith('.jpg')
        || name.endsWith('.jpeg')
        || name.endsWith('.gif')
        || name.endsWith('.bmp')) {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                fileName: file.name
            });

            if (typeof that.parent !== 'undefined' && that.parent
                && typeof that.id !== 'undefined' && that.id
                && typeof that.parent.setOrChangeImage === 'function'){
                that.parent.setOrChangeImage(that.id, file);
            }
        }

      this.unlock();
    };
    reader.readAsDataURL(file);
  }

  //signiture
    handleClickOpen(id, modal) {
        debugger;
        this.lastId = id;

        this.setState({
            modal: true
        });

        this.clearCanvas();
    }

    handleClose(modal) {
        this.setState({
            modal: false
        });
    }

    HandleDone = () => {
        let that = this;
        if (typeof this.lastId !== 'undefined' && this.lastId !== null) {
            let canvas = this.state.canvas[0];
            canvas._canvas.toBlob(function(blob) {
                that.wizardManger.wizardData.selectedStep = "imageUpload";
                that.setState({docSigniture: blob});
                that.wizardManger.wizardData.docSigniture = blob;
                that.setState({docID:-612142});
                that.wizardManger.wizardData.docId =-612142;
                that.setState({imageFile: that.lastId});
                that.wizardManger.wizardData.uploadImage =that.lastId;
                that.semnareDocumentGDPR();
            });
        }
        this.handleClose("modal");

    };

    semnareDocumentGDPR(){
        let that = this;
        that.setState({progressIndicatorOn: true});
        this.wizardManger.advancedSigned()
            .then(function(resp){
                that.state.showImagePreview = true;
                that.state.downloadImageLink = resp.downloadLink;
                that.setState({progressIndicatorOn: false});
                that.forceUpdate();
                alert("Documentul a fost semnat cu succes!");
            })
            .catch(function(err){
                that.setState({progressIndicatorOn: false});
                alert("A avut loc o eroare: " + err);
            });
    }

    clearCanvas(){
            let canvas = this.state.canvas[0];
            canvas.clear();
    }


    downloadView(){
        debugger;
        window.location.href = this.state.downloadImageLink;
    }
    //end of
  handleSubmit(e) {
    e.preventDefault();
      // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    let that = this;
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
    this.state.showImagePreview = false;
    if (typeof that.parent !== 'undefined' && that.parent
        && typeof that.id !== 'undefined' && that.id
        && typeof that.parent.removeImage === 'function'){
        that.parent.removeImage(that.id);
    }
  }

  getProgressIndicator(){
        if (!this.state.progressIndicatorOn){
            return("");
        }

        return(
            <div>
                <CircularProgress
                    className={this.props.classes.progress}
                />

                <h4 className={this.props.classes.notifMessage}>Se semneaza documentul</h4>
            </div>
        );
  }

  render() {
    let that = this;
    that.tryLoadPreloadedImage();

    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      customProps
    } = this.props;

    const { classes } = this.props;

    debugger;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" accept=".jpg,.jpeg,.png,.gif,.bmp,image/gif,image/jpeg,image/png,image/bmp" />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt={this.state.fileName} onClick={() => this.handleClick()} />
        </div>
        <div>
          {this.state.file === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? "Adauga imagine" : "Selectați imaginea"}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()} data-toggle="tooltip" title="Edit">
                <i className="fas fa-pencil-alt" />
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleClickOpen(this.state.file,"modal")} data-toggle="tooltip" title="Signature"
              >
                 <i class="fas fa-file-signature"></i>
              </Button>
                 <Button
                     {...removeButtonProps}
                     disabled={!this.state.showImagePreview}
                     onClick={() => this.downloadView()} data-toggle="tooltip" title="View">
                <i className="fas fa-eye" />
              </Button>
                <Button
                    {...removeButtonProps}
                    onClick={() => this.handleRemove()} data-toggle="tooltip" title="Delete"
                >
                <i class="fas fa-trash-alt"></i>
              </Button>
            </span>
          )}
            <Dialog
                fullScreen={true}
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={this.state.modal}
                transition={Transition}
                keepMounted
                onClose={() => this.handleClose("modal")}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Semnare Document</h4>
                </DialogTitle>
                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}>
                    <div className={classes.schetchFieldDiv} style={{width:this.state.utils.signWidth,height:this.state.utils.signHeight,border: "1px solid grey",left: "5px",position: "absolute"}}>
                        <SketchField
                            ref={ref => (this.state.canvas[0] = ref)}
                            width={this.state.utils.signWidth}
                            height={this.state.utils.signHeight}
                            tool={Tools.Pencil}
                            lineColor="black"
                            lineWidth={2}
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={() => this.handleClose("modal")} color="simple">
                        Close
                    </Button>
                    <Button
                        onClick={() => this.HandleDone()}
                        block
                        color="success"
                    >
                        Aplica
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
          {this.getProgressIndicator()}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  customProps: PropTypes.object
};

export default withStyles(style)(ImageUpload);

