import Dashboard from "views/Dashboard/Dashboard.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import ChangePass from "views/Forms/ChangePass.jsx";
import eSign from "views/Forms/WizardSteps/eSign.jsx";


import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import NoteAdd from "@material-ui/icons/NoteAdd";
import ListAlt from "@material-ui/icons/ListAlt";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Security from "@material-ui/icons/Security";
import ExitToApp from "@material-ui/icons/ExitToApp";


var pages = [

].concat(pagesRoutes);

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Pagina principala",
    icon: DashboardIcon,
    component: Dashboard
  },
    {
        path: "/new-contract",
        name: "Contract nou",
        icon: NoteAdd,
        component: Wizard
    },
    {
        path: "/my-contracts",
        name: "Contractele mele",
        icon: ListAlt,
        component: Dashboard,
        lightRegister: "REGISTRU_CONTRACTE_RESTART"
    },
    //{
    //    path: "/active-offers",
    //    name: "Oferte active",
    //    icon: LocalOffer,
    //    component: Dashboard,
    //    lightRegister: "CAMPANII"
    //},
    {
        path: "/change_pass",
        name: "Schimba parola",
        icon: Security,
        component: ChangePass
    },
    {
        path: "/eSign",
        name: "Semnare electronica",
        icon: Security,
        component: eSign
    },
  {
    path: "/auth/login",
    name: "Delogare",
    icon: ExitToApp
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
