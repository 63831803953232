import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// material-ui components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Sign from "@material-ui/icons/BorderColor";
import Read from "@material-ui/icons/Visibility";
import Assignment from "@material-ui/icons/Assignment";
import { SketchField, Tools } from "react-sketch";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as utils from "helper/utils.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const style = {
    cardText:{
        width:"100%",
        textAlign:"center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    form_box:{
        width: "100%",
        float: "left",
        padding: "10px 50px",
    },
    form_group : {
        width: "100%",
        float: "left",
        marginBottom: "18px",
    },
    label :{
        width: "100%",
        float: "left",
        color: "#333",
        fontWeight: "600",
        marginBottom: "8px",
    },
    label2 :{
        width: "100%",
        color: "#333",
        fontWeight: "600",
        marginBottom: "8px",
        marginLeft:"15px"
    },
    input2: {
        width: "35%",
        height: "32px",
        boxShadow: "inset 0px 0px 4px #ccc",
        border: "1px solid #ccc",
        padding: "6px 12px",
        margin:"1%"
    },
    input: {
        width: "100%",
        float: "left",
        height: "32px",
        boxShadow: "inset 0px 0px 4px #ccc",
        border: "1px solid #ccc",
        padding: "6px 12px",
    },
    notifMessage: {
        display: "inline-block",
        overflow: "auto",
        marginLeft: "10px",
        marginTop:  "18px"
    },
    signbtn:{
        backgroundColor: "#4caf50",
        boxShadow: "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)",
        color: "white",
        cursor: "pointer",
        padding: "12px 20px",
        minWidth: "auto",
        fontSize: "12px",
        minHeight: "auto",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        textAlign: "center",
        fontWeight: "400",
        willChange: "box-shadow, transform",
        lineHeight: "1.42857143",
        whiteSpace: "nowrap",
        touchAction: "manipulation",
        borderRadius: "3px",
        textTransform: "uppercase",
        letterSpacing: "0",
        verticalAlign: "middle",
        border: "none",
        "&:hover,&:focus": {
            color: "#FFFFFF",
            backgroundColor: "#4caf50",
            boxShadow: "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)",
        },
    },


};

class eSign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info_caracter_comercial: false,
            reclama_publicitate: false,
            grad_multumire: false,
            utils: utils.utils(),
            canvas: [],
            docID: "",
            docSigniture:"",
            downloadLink:"",
            showPreview:false,
            showPDF:false,
            showUpload:true,
            firstName:"",
            lastName:"",
            cnp:"1820203414104",
            pin:"1234",
            username:"pft4542dsdf3TTGDDVCX6gA",
            parola:"sdf^&dsf#$sddfFGrQADS33",
            otp:"",
            progressIndicatorOn: false
        };

        this.wizardManger = window.wizardManager;
        //this.state.firstName = window.wizardManager.loginManager.userData.npPerson.split(" ")[0];
        //this.state.lastName = window.wizardManager.loginManager.userData.npPerson.split(" ")[1];
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    reloadState(){
        this.wizardManger.setDataValue('formularGdpr', null);
        this.setState({ formularGdpr: "", formularGdprData: null });
    }

    sendState() {
        return this.state;
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    phoneNumber(value)
    {
        var phoneno = /^\d{10}$/;
        if(value.match(phoneno))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    eSignDoc(docId){
        let that = this;
        that.setState({progressIndicatorOn: true});
        window.wizardManager.wizardData.cnp = this.state.cnp;
        window.wizardManager.wizardData.pin = this.state.pin;
        window.wizardManager.wizardData.docId = docId;
        window.wizardManager.wizardData.utilizator = this.state.username;
        window.wizardManager.wizardData.parola = this.state.parola;
        this.wizardManger.eSigned()
            .then(function(resp){
                that.state.showPreview = true;
                that.state.downloadLink = resp.downloadLink;
                that.forceUpdate();
                that.setState({progressIndicatorOn: false});
                alert("Documentul a fost semnat cu succes!");
            })
            .catch(function(err){
                that.setState({progressIndicatorOn: false});
                alert("A avut loc o eroare: " + err);
            });
    }

    verifyNP(nume){
        debugger;
        if(this.state.firstName == "" || this.state.lastName == "" ) {
            return true;
        }else if(!nume.includes(",") || nume ==""){
            return true;
        }else
            return false;
    }

    handleClickOpen(id, modal) {
        this.lastId = id;
        let numePersoana = this.state.firstName +","+ this.state.lastName;
        if(this.lastId == ""){
            alert("Incarcati un document PDF!");
        }else if(this.verifyNP(numePersoana)){
            alert("Nume Invalid! Va rugam sa completati numele si prenumele!");
        } else {
            this.setState({
                modal: true
            });
        }
        this.clearCanvas();
    }

    handleClose(modal) {
        this.setState({
            modal: false
        });
    }

    handleClickOpenOTP(modal) {
        this.setState({
            modalOTP: true
        });

        this.clearCanvas();
    }

    handleCloseOTP(modal) {
        this.setState({
            modalOTP: false
        });
    }

    HandleDoneOTP = () => {
        let that = this;
        window.wizardManager.wizardData.numePersoana = this.state.lastName+","+this.state.firstName;
        if(that.state.otp!="1234567"){
            alert("OTP invalid!")
        } else{
            this.wizardManger.generareSemnaturaElectronica()
                .then(function(resp){
                    that.forceUpdate();
                    that.setState({progressIndicatorOn: false});
                    alert("Semnatura electronica a fost generata cu succes!");
                })
                .catch(function(err){
                    that.setState({progressIndicatorOn: false});
                    alert("A avut loc o eroare: " + err);
                });
        }
        this.handleCloseOTP("modal");

    };

    HandleDone = () => {
        let that = this;
        window.wizardManager.wizardData.numePersoana = this.state.lastName+","+this.state.firstName;
        if(that.state.otp!="1234567"){
            alert("OTP invalid!");
        } else{
            if (typeof this.lastId !== 'undefined' && this.lastId !== null) {
                let canvas = this.state.canvas[0];
                canvas._canvas.toBlob(function(blob) {
                    that.setState({docSigniture: blob});
                    that.wizardManger.wizardData.docSigniture = blob;
                    that.setState({docID: that.lastId});
                    that.wizardManger.wizardData.docId =that.lastId;
                    that.wizardManger.wizardData.firstName = that.state.firstName;
                    that.wizardManger.wizardData.lastName = that.state.lastName;
                    that.semnareDocumentGDPR();
                });
            }
        }
        this.handleClose("modal");

    };

    semnareDocumentGDPR(){
        let that = this;
        that.setState({progressIndicatorOn: true});
        this.wizardManger.advancedSigned()
            .then(function(resp){
                that.state.docID = resp.id;
                that.state.showPreview = true;
                //that.state.downloadLink = resp.downloadLink;
                that.setState({progressIndicatorOn: false});
                that.generareSemnaturaElectronica(that.state.docID);
            })
            .catch(function(err){
                that.setState({progressIndicatorOn: false});
                alert("A avut loc o eroare: " + err);
            });
    }

    generareSemnaturaElectronica(docId){
        let that = this;
        this.wizardManger.generareSemnaturaElectronica()
            .then(function(resp){
                that.setState({progressIndicatorOn: false});
                that.eSignDoc(docId);
            })
            .catch(function(err){
                that.setState({progressIndicatorOn: false});
                alert("A avut loc o eroare: " + err);
            });

    }

    clearCanvas(){
        let canvas = this.state.canvas[0];
        canvas.clear();
    }


    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    getProgressIndicator(){
        if (!this.state.progressIndicatorOn){
            return("");
        }

        return(
            <div>
                <CircularProgress
                    className={this.props.classes.progress}
                />

                <h4 className={this.props.classes.notifMessage}>Se semneaza documentul</h4>
            </div>
        );
    }

    updateInputValue(evt) {
        debugger;
        if(evt.target.name=='firstName') {this.setState({
            firstName: evt.target.value
        });
        }
        if(evt.target.name=='lastName'){
            this.setState({
                lastName: evt.target.value
            });
        }
        if(evt.target.name=='cnp'){
            this.setState({
                cnp: evt.target.value
            });
        }
        if(evt.target.name=='pin'){
            this.setState({
                pin: evt.target.value
            });
        }
        if(evt.target.name=='utilizator'){
            this.setState({
                utilizator: evt.target.value
            });
        }
        if(evt.target.name=='parola'){
            this.setState({
                parola: evt.target.value
            });
        }
        if(evt.target.name=='otp') {
            this.setState({
                otp: evt.target.value
        });
        }

    }


    onChange(event){
        debugger;
        let that = this;
        let selectedFile = event.target.files;
        let file = null;
        let fileName = "";
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            fileName = fileToLoad.name;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in console
                console.log(file);
                window.wizardManager.wizardData.pdfFile = file;
                that.wizardManger.uploadPDF()
                    .then(function(resp){
                        that.state.showUpload = false;
                        that.state.showPDF = true;
                        that.state.downloadLink = resp.downloadLink;
                        that.state.docID = resp.idFile;
                        that.forceUpdate();
                        alert("Documentul a fost incarcat cu succes!");
                    })
                    .catch(function(err){
                        that.setState({progressIndicatorOn: false});
                        alert("A avut loc o eroare: " + err);
                    });
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }

    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({ [stateName]: event.target.value });
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "phone":
                if (this.phoneNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
    }

    isValidated() {
        this.wizardManger.setDataValue('formularGdpr', this.state);
        return true;
    }
    render() {
        let that = this;
        const { classes } = this.props;
        return (

            <GridContainer justify="center">
                <Card>
                    <CardHeader color="warning" text>
                        <CardText className={classes.cardText} color="success">
                            <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Semnare electronica</h4>
                        </CardText>
                    </CardHeader>
                    <CardBody class="nopadding">
                        <GridItem xs={12} sm={12} md={12} lg={12} class="nopadding">
                            {/*https://qa-ora.documenta.ro:5443/DMSWS/api/v1/download/4f7cc3bec3ac176ec388c2b0c3884b0bc2a7c3820b3f5c575ac3b0c3a248c2a72353e28098cb9cc396e280a2c38e5b4837/by_id/-595764*/}
                            {this.state.showUpload ? <div class="card_box" style={{textAlign:"center"}}>
                                <label class={classes.label}>Va rugam incarcati un document pdf</label>
                                 <input type="file" accept="application/pdf" name="file" onChange={(e)=>that.onChange(e)}/>

                            </div>:null}
                            {this.state.showPDF
                            ? <div><div class="pdf_header">
                                { this.state.showPreview
                                    ? <a target="_blank" href={this.state.downloadLink} class="download_btn MuiButtonBase-root-230 MuiButton-root-204 MuiButton-text-206 MuiButton-flat-209 RegularButton-button-175 RegularButton-success-179 animated fadeInDownBig"><i class="fa fa-download"></i></a>
                                    : null
                                }
                               {/* <a target="_blank" href="https://asigurari.documenta-dms.com/Documente/TERMENI%20SI%20CONDITII.pdf?#view=fit" class="download_btn MuiButtonBase-root-230 MuiButton-root-204 MuiButton-text-206 MuiButton-flat-209 RegularButton-button-175 RegularButton-success-179 animated fadeInDownBig"><i class="fa fa-eye"></i></a>*/}
                            </div>
                            <div class="pdf_box">
                                <object data={this.state.downloadLink} type="application/pdf" width="100%" height="100%"></object>
                            </div>
                                </div>
                                :null}

                                <div class={classes.form_box}>
                                <div class={classes.form_group}>
                                    <label class={classes.label}>Nume</label>
                                    <input  name="lastName"  type="text"  class={classes.input} onChange={this.updateInputValue}/>
                                </div>
                                <div class={classes.form_group}>
                                    <label class={classes.label}>Prenume</label>
                                    <input name="firstName"  type="text" class={classes.input} onChange={this.updateInputValue}/>
                                </div>
                                <div class={classes.form_group}>
                                    <button onClick={evt => this.handleClickOpen(this.state.docID,"modal")} class={classes.signbtn}>Semneaza acum</button>
                                </div>

                            </div>
                        </GridItem>
                        {this.getProgressIndicator()}
                    </CardBody>
                </Card>

                <Dialog
                fullScreen={true}
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={this.state.modal}
                transition={Transition}
                keepMounted
                onClose={() => this.handleClose("modal")}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Semnare Document</h4>
                </DialogTitle>
                    <div class={classes.form_group}>
                        <label class={classes.label2}>OTP</label>
                        <input  name="otp"  type="text" class={classes.input2} onChange={this.updateInputValue}/>
                        <label class={classes.label2}>CNP</label>
                        <input  name="cnp" type="text" defaultValue="1820203414104" class={classes.input2} onChange={this.updateInputValue}/>
                    </div>
                    <label class={classes.label} hidden>Date semnatura electronica</label>
                    <div class={classes.form_group}>
                        <label class={classes.label2} hidden>CNP</label>
                        <input  name="cnp" type="hidden" defaultValue="1820203414104" class={classes.input2} onChange={this.updateInputValue}/>
                        <label class={classes.label2} hidden>PIN</label>
                        <input  name="pin" type="hidden" defaultValue="1234" class={classes.input2} onChange={this.updateInputValue}/>
                        <label class={classes.label2} hidden>Utilizator</label>
                        <input  name="utilizator" type="hidden" defaultValue="pft4542dsdf3TTGDDVCX6gA" class={classes.input2} onChange={this.updateInputValue}/>
                        <label class={classes.label2} hidden>Parola</label>
                        <input name="parola"  type="hidden" defaultValue="sdf^&dsf#$sddfFGrQADS33" class={classes.input2} onChange={this.updateInputValue}/>
                    </div>


                <DialogContent
                    id="modal-slide-description"
                    className={classes.modalBody}>
                    <div className={classes.schetchFieldDiv} style={{width:this.state.utils.signWidth,height:this.state.utils.signHeight,border: "1px solid grey",left: "5px",position: "absolute"}}>
                        <SketchField
                            ref={ref => (this.state.canvas[0] = ref)}
                            width={this.state.utils.signWidth}
                            height={this.state.utils.signHeight}
                            tool={Tools.Pencil}
                            lineColor="black"
                            lineWidth={2}
                        />
                    </div>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    <Button onClick={() => this.handleClose("modal")} color="simple">
                        Close
                    </Button>
                    <Button
                        onClick={() => this.HandleDone()}
                        block
                        color="success"
                    >
                        Aplica
                    </Button>
                </DialogActions>
            </Dialog>

            </GridContainer>
        );
    }
}
export default withStyles(style)(eSign);

