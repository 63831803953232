import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";
import {infoColor} from "../../../assets/jss/restart-energy";
import {successColor} from "../../../assets/jss/restart-energy";

import * as utils from "helper/utils.js";


const style = {
    cardText:{
        width:"100%",
        textAlign:"center"
    },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  iconInfo: {
      color: infoColor
  },
  iconSuccess: {
      color: successColor
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

class StepSelectType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      typeState: "error",
      selectedValue: "",
      utils: utils.utils(),
      autoSelectedType:""
    };

    this.wizardManger = window.wizardManager;
    //if was autenticated automatically
    this.checkAutoSelectedType();
  }
  checkAutoSelectedType(){
      this.state.autoSelectedType = sessionStorage.getItem("insuranceType");
      if(this.state.autoSelectedType!=null && this.state.autoSelectedType!=""){
          this.wizardManger.setDataValue('type', this.state.autoSelectedType);
          this.setState({ type: this.state.autoSelectedType });
          this.setState({ typeState: "success" });
          this.setState({ selectedValue: this.state.autoSelectedType })
          this.state.utils.triggerStepNext();
          this.state.utils.showStepNext();
      }
  }
  sendState() {
    return this.state;
  };

  reloadState(){

  }

  handleChange = name => event => {
      debugger;
    this.wizardManger.setDataValue('type', name);
    this.setState({ type: event.target.name });
    this.setState({ typeState: "success" });
    this.setState({ selectedValue: event.target.value })
    this.state.utils.triggerStepNext();
    this.state.utils.showStepNext();
  };


  isValidated() {
      if( this.state.autoSelectedType != "") {
          this.state.type=this.state.autoSelectedType;
          this.state.typeState= "success";
      }
      if (
          this.state.typeState === "success"
      ) {
          return true;
      } else {
          if (this.state.typeState !== "success") {
            return false;
          }
      }
      return false;


  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                  <CardHeader color="warning" text>
                      <CardText className={classes.cardText} color="success">
                          <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Alege tipul de asigurare</h4>
                          <h4 className={classes.cardCategoryWhite}>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                              <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                          </h4>
                      </CardText>
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                          <GridItem xs={8} sm={4}>
                              <div className={classes.choiche}>
                                  <Radio
                                    value="vehicle"
                                    checked={this.state.selectedValue === 'vehicle'}
                                      tabIndex={-1}
                                      onClick={this.handleChange("vehicle")}
                                      checkedIcon={
                                          <i
                                              className={
                                                  "fas fa-car " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      icon={
                                          <i
                                              className={
                                                  "fas fa-car " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      classes={{
                                          checked: classes.iconCheckboxChecked,
                                          root: classes.iconCheckbox
                                      }}
                                  />
                                  <h6>VEHICLE INSURANCE</h6>
                              </div>
                          </GridItem>
                            <GridItem xs={8} sm={4}>
                            <div className={classes.choiche}>
                            <Radio
                            tabIndex={-1}
                            value="property"
                            checked={this.state.selectedValue === 'property'}
                            onClick={this.handleChange("property")}
                            checkedIcon={
                            <i
                            className={
                                "fas fa-home " + classes.iconCheckboxIcon + " " + classes.iconInfo
                            }
                            />
                            }
                            icon={
                            <i
                            className={
                                "fas fa-home " + classes.iconCheckboxIcon + " " + classes.iconInfo
                            }
                            />
                            }
                            classes={{
                                checked: classes.iconCheckboxChecked,
                                    root: classes.iconCheckbox
                            }}
                            />
                            <h6>PROPERTY INSURANCE</h6>
                            </div>
                            </GridItem>
                          <GridItem xs={8} sm={4}>
                              <div className={classes.choiche}>
                                  <Radio
                                      tabIndex={-1}
                                      value="life"
                                      checked={this.state.selectedValue === 'life'}
                                      onClick={this.handleChange("life")}
                                      checkedIcon={
                                          <i
                                              className={
                                                  "fas fa-heart " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      icon={
                                          <i
                                              className={
                                                  "fas fa-heart " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      classes={{
                                          checked: classes.iconCheckboxChecked,
                                          root: classes.iconCheckbox
                                      }}
                                  />
                                  <h6>LIFE INSURANCE</h6>
                              </div>
                          </GridItem>
                          <GridItem xs={8} sm={4}>
                              <div className={classes.choiche}>
                                  <Radio
                                      tabIndex={-1}
                                      value="family"
                                      checked={this.state.selectedValue === 'family'}
                                      onClick={this.handleChange("family")}
                                      checkedIcon={
                                          <i
                                              className={
                                                  "fas fa-user-friends " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      icon={
                                          <i
                                              className={
                                                  "fas fa-user-friends " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      classes={{
                                          checked: classes.iconCheckboxChecked,
                                          root: classes.iconCheckbox
                                      }}
                                  />
                                  <h6>FAMILY PROTECTION</h6>
                              </div>
                          </GridItem>
                          <GridItem xs={8} sm={4}>
                              <div className={classes.choiche}>
                                  <Radio
                                      tabIndex={-1}
                                      value="health"
                                      checked={this.state.selectedValue === 'health '}
                                      onClick={this.handleChange("health")}
                                      checkedIcon={
                                          <i
                                              className={
                                                  "fas fa-hands-helping " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      icon={
                                          <i
                                              className={
                                                  "fas fa-hands-helping " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      classes={{
                                          checked: classes.iconCheckboxChecked,
                                          root: classes.iconCheckbox
                                      }}
                                  />
                                  <h6>HEALTH INSURANCE</h6>
                              </div>
                          </GridItem>
                          <GridItem xs={8} sm={4}>
                              <div className={classes.choiche}>
                                  <Radio
                                      tabIndex={-1}
                                      value="business"
                                      checked={this.state.selectedValue === 'business'}
                                      onClick={this.handleChange("business")}
                                      checkedIcon={
                                          <i
                                              className={
                                                  "fas fa-business-time " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      icon={
                                          <i
                                              className={
                                                  "fas fa-business-time " + classes.iconCheckboxIcon + " " + classes.iconInfo
                                              }
                                          />
                                      }
                                      classes={{
                                          checked: classes.iconCheckboxChecked,
                                          root: classes.iconCheckbox
                                      }}
                                  />
                                  <h6>BUSINESS INSURANCE</h6>
                              </div>
                          </GridItem>

                      </GridContainer>
                  </CardBody>
              </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(StepSelectType);
