const cardBodyStyle = {
  cardBody: {
    padding: "15px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
      margin: "0px;",
  },
  cardBodyBackground: {
    position: "relative",
    zIndex: "2",
    minHeight: "280px",
    paddingTop: "40px",
    paddingBottom: "40px",
    maxWidth: "440px",
    margin: "0 auto"
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyFormHorizontal: {
    paddingLeft: "15px",
    paddingRight: "15px",
    "& form": {
      margin: "0"
    }
  },
  cardPricing: {
    padding: "15px!important",
    margin: "0px!important"
  },
  cardSignup: {
    padding: "0px 30px 0px 30px"
  },
  cardBodyColor: {
    borderRadius: "6px",
    "&": {
      "h1, h2, h3": {
        "& small": {
          color: "rgba(255, 255, 255, 0.8)"
        }
      }
    }
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  cardBodyCalendar: {
    padding: "0px !important"
  },
  nopadding:{
    padding: "0"
},
    pdf_header :{
    width: "100%",
    float: "left",
    marginBottom: "30px",
    backgroundColor: "#ffffff",
    padding: "10px 50px",
    boxShadow: "0px 3px 6px 0px #cccccc69"
},
    download_btn :{
    float: "right",
        padding: "6px 13px",
    boxShadow: "0px 1px 3px 0px #0000008f",
    bordeRadius: "3px",
    color: "#f9c00a",
    cursor: "pointer",
    transition: ".4s",
    fontSize: "12px",
  },
  pdf_box :{
    width: "86%",
    float: "none",
    border: "1px solid #ccc",
    height: "460px",
    overflow: "hidden",
    margin: "auto",
    padding: "15px",
    marginBottom: "30px",
    iframe :{
        width: "100%",
        height: "100%",
    }
},

form_box :{
    width: "100%",
    float: "left",
    padding: "10px 50px"
  },

};

export default cardBodyStyle;
